import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function updateNotificationsRules({ userId, badReview, newReview }) {
  try {
    const res = await axios.put(
      `/users/${userId}/update_notifications`,
      { rails_settings_setting_object: { bad_review: badReview, new_review: newReview } },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function updateAuthenticationToken() {
  try {
    const res = await axios.put(
      `/users/update_token`,
      {},
      {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
